'use client';

import { useEffect } from 'react';
import { useScrollPosition } from '@frontend/jetlend-web-ui/src/hooks/useScrollPosition';
import styles from './jivoChatMobileAnimation.module.scss';

// Active JivoButton when page scrolled more than that value
const ACTIVE_SCROLL_OFFSET = 140;

/**
 * Implement interactive animation for JivoChat Mobile Button.
 * By design JivoChat button overlapping fullscreen background and active elements,
 *  so we just hide this button on first screen and back when screen scrolling down.
 */
export default function JivoChatMobileAnimation() {
    const scrollPosition = useScrollPosition();
    const shouldBeHidden = scrollPosition <= ACTIVE_SCROLL_OFFSET;

    useEffect(() => {
        document.body.classList.add(styles['jivo-chat-button']);
        if (shouldBeHidden) {
            document.body.classList.remove(styles['jivo-chat-button--active']);
        } else {
            document.body.classList.add(styles['jivo-chat-button--active']);
        }

        return () => {
            document.body.classList.remove(styles['jivo-chat-button']);
            document.body.classList.remove(styles['jivo-chat-button--active']);
        };
    }, [ shouldBeHidden ]);

    return null;
}