'use client';

import React, {
    useCallback,
    useState,
} from 'react';

import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { StaticImageData } from 'next/image';
import styles from './slidesShow.module.scss';
import Image from '@app/components/server/Image/Image';

export interface IProps {
    title: string;
    backgroundImage?: string;
    contentImage?: string | StaticImageData;
    description?: string | JSX.Element;
    value?: string;
    loading?: boolean;
    verticalAlignment?: 'start'|'center'|'end';
    footer?: JSX.Element|React.ReactNode;
}

const SlidesShowBackgroundItem: React.FC<IProps> = props => {
    const {
        title,
        description,
        footer,
        backgroundImage,
        contentImage,
        verticalAlignment,
        loading,
        value,
    } = props;

    const [loaded, setLoaded] = useState(false);

    const didLoaded = useCallback(() => {
        setLoaded(true);
    }, []);

    return (
        <div className={styles.header}>
            {backgroundImage && (
                <div className={styles.backgroundContainer}>
                    <img className={styles.background} src={backgroundImage} alt="" />
                </div>
            )}
            {loading && <div className={styles.loaderIcon}><LoaderBlock size="large" /></div>}
            <div className={styles.headerTitle}>
                <div className={styles['scrollContent']}>
                    <div
                        className={buildClassNames(styles, [
                            'content__description',
                            `content__description--vertical-alignment-${verticalAlignment || 'end'}`,
                        ])}
                    >
                        {contentImage && (
                            <div className={styles.contentImage}>
                                <Image src={contentImage} alt="" onLoad={didLoaded} quality={loaded ? 100 : 10} />
                            </div>
                        )}
                        <div className={styles.headerTitle_text}>
                            {title}
                        </div>
                        <div className={styles.headerDescription_text}>
                            {description}
                        </div>
                        <div className={styles.headerValue_text}>
                            {value}
                        </div>
                    </div>
                    {footer && React.Children.count(footer) > 0 && (
                        <div className={styles['content__footer']}>
                            {footer}
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default SlidesShowBackgroundItem;
