import React, {
    useRef,
    useEffect,
} from 'react';

import styles from './slidesShow.module.scss';

export interface IProps {
    active: boolean;
    current?: boolean;
    duration?: number;
    disableNavigation?: boolean;
}

const INITIAL_BAR_INLINE_STYLES: React.CSSProperties = {
    width: '0%',
};

const SlideNavigationItem: React.FC<IProps> = props => {
    const {
        active,
        current,
        duration = 0,
    } = props;
    const barRef = useRef<any>();

    useEffect(() => {
        const barElement = barRef.current;
        if (!barElement) {
            return;
        }

        if (current) {
            barElement.style.width = '0%';
            const timerId = setTimeout(() => {
                if (!props.disableNavigation) {
                    barElement.style.width = '100%';
                    barElement.style.transition = `all ${duration.toFixed(0)}ms linear`;
                }
            }, 10);

            return () => {
                clearTimeout(timerId);
            };
        }
        barElement.style.transition = 'none';

        if (active) {
            barElement.style.width = '100%';
        }  else {
            barElement.style.width = '0%';
        }

    }, [ active, current, duration, barRef ]);

    return (
        <div className={styles.navigationItem}>
            <div className={styles.navigationItem_background} />
            <div ref={barRef} className={styles.navigationItem_progress} style={INITIAL_BAR_INLINE_STYLES} />
        </div>
    );
};

export default SlideNavigationItem;