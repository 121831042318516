import {
    useState,
    useCallback,
    useEffect,
} from 'react';

/**
 * TODO
 * @category Hooks
 */
export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = useCallback(() => {
        const position = window.scrollY;
        setScrollPosition(position);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scrollPosition;
};