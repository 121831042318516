import React, {
    useCallback,
    useEffect,
} from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import SlideNavigationItem from './SlideNavigationItem';

import styles from './slidesShow.module.scss';

export interface IProps extends React.PropsWithChildren {
    actions?: React.ReactNode|JSX.Element;
    activeSlide?: number;
    closable?: boolean;
    disableNavigation?: boolean;
    durationSeconds?: number | number[];
    onSlideChange?: (slideIndex: number) => void;
    onClose?: () => void;
}

const SLIDE_TIME = 7000;

const SlidesShow: React.FC<IProps> = props => {
    const {
        activeSlide = 0,
        onSlideChange,
    } = props;

    const slidesArray = React.Children.toArray(props.children);
    const customDurationSeconds = Array.isArray(props.durationSeconds) ? props.durationSeconds[activeSlide] : props.durationSeconds;
    // eslint-disable-next-line no-nested-ternary
    const safeActiveSlideIndex = activeSlide < 0
        ? 0
        : (activeSlide >= slidesArray.length ? slidesArray.length - 1 : activeSlide);

    useEffect(() => {
        if (!onSlideChange) {
            return;
        }

        const timerId = setTimeout(() => {
            if (activeSlide < slidesArray.length - 1) {
                onSlideChange(activeSlide + 1);
            } else {
                onSlideChange(0);
            }

        }, customDurationSeconds ?? SLIDE_TIME);

        return () => {
            clearTimeout(timerId);
        };
    }, [ onSlideChange, activeSlide ]);

    const didSlideClicked = useCallback(() => {
        if (!onSlideChange) {
            return;
        }

        if (activeSlide < slidesArray.length - 1) {
            onSlideChange(activeSlide + 1);
        } else {
            onSlideChange(0);
        }
    }, [ onSlideChange, activeSlide ]);

    return (
        <button onClick={didSlideClicked} className={styles.container}>
            <div className={styles.contentHeight}>
                {slidesArray.map((slide, index) => (
                    <div
                        key={`history-slide-${index}`}
                        className={buildClassNames(styles, ['slide', index === safeActiveSlideIndex && 'slide--active'])}
                    >
                        {slide}
                    </div>
                ))}
            </div>
            <div className={styles.navigation}>
                {slidesArray.map((slide, index) => (
                    <SlideNavigationItem
                        key={index}
                        current={index === activeSlide}
                        active={index <= activeSlide}
                        duration={customDurationSeconds ?? SLIDE_TIME}
                        disableNavigation={props.disableNavigation}
                    />
                )
                )}
            </div>

            <div className={styles.footer}>
                {props.actions}
            </div>
        </button>
    );
};

export default SlidesShow;